<template lang="pug">
#app
  v-container(grid-list-md, fluid)
    v-layout(v-if="$root.user.permissao != 0", row, wrap)
      AccessLock
    v-layout(v-if="$root.user.permissao == 0", row, wrap)
      v-flex(lg12)
        form(@submit.prevent="salvar")
          v-card.elevation-1
            v-card-text
              v-layout(row, wrap)
                v-flex.md6.xs12
                  v-text-field(label="Nome", color="gray", required, v-validate="'required'", :error-messages="errors.collect('nome')", data-vv-name="nome", data-vv-as="Nome", type="text", v-model='licenca.nome')
                v-flex.md6.xs12
                  v-menu(v-model="menuDataInicio", :close-on-content-click="false", :nudge-right="40", lazy, transition="scale-transition", offset-y, full-width, min-width="290px")
                    template(v-slot:activator="{ on }")
                      v-text-field(:value="dataInicioFormatada", label="Data de Início", v-on="on", clearable, readonly, @click:clear="licenca.data_inicio = null;" )
                    v-date-picker(v-model="licenca.data_inicio", @input="menuDataInicio = false")
              v-layout(row, wrap)
                v-flex.xs12
                  v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar
</template>

<script>
import _ from 'lodash';
import AccessLock from '@/components/AccessLock';

export default {
  components: {
    AccessLock
  },
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    loading: false,
    licenca: {},
    menuDataInicio: false,
  }),
  computed: {
    dataInicioFormatada () {
      return this.licenca.data_inicio ? this.$moment(this.licenca.data_inicio).format('DD/MM/YYYY') : '';
    },
  },
  mounted () {
    const vm = this;
    if (vm.$route.params.id) {
      vm.getLicenca(vm.$route.params);
    }
  },
  methods: {
    async save (licenca) {
      const vm = this;
      try {
        const response = await vm.$axios.post('/licenca', licenca);
        licenca.id = response.data.id;
        vm.$router.push({ name: 'LicencaLista', params: { id: response.data.id } });
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Licença salva com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar a licença. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async update (licenca) {
      const vm = this;
      try {
        const response = await vm.$axios.put(`/licenca/${licenca.id}`, licenca);
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Licença salva com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao atualizar a licença. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async salvar (scope) {
      const vm = this;
      try {
        vm.loading = true;  
        const result = await vm.$validator.validateAll();
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm[vm.licenca.id ? 'update' : 'save'](vm.licenca);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
    async getLicenca (params) {
      let vm = this;
      try {
        const response = await vm.$axios.get(`/licenca/${params.id}`);
        vm.licenca = response.data || {};
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>

